<template>
  <iframe :key="src" :src="src" class="frame nopadding" />
</template>

<script>
import { getApplicationId, preOpen } from '@/api/login'

export default {
  components: {},
  data() {
    return {
      src: '',
      timeStr: new Date()
    }
  },

  watch: {
    $route: {
      immediate: true,
      handler(value) {
        const rt = this.filterNode(value.path, this.$store.getters.permission_routers)
        const dynamicField = rt.dynamicField && JSON.parse(rt.dynamicField)
        const url = dynamicField && dynamicField.targetValue
        this.init(url)
      }
    }
  },

  methods: {
    async init(url) {
      const application = await getApplicationId()
      const openData = await preOpen({
        applicationId: application.notaryApplicationId,
        props: {
          target_value: url,
          hideMenu: true
        }
      })
      this.src = openData.data && openData.data.redirectUri + '&_LOGIN_TIMESTAMP=' + this.timeStr.getTime()
    },
    filterNode(path, menus) {
      let matched = {}
      menus.some((item) => {
        const dynamicField = item.dynamicField && JSON.parse(item.dynamicField)
        if (dynamicField && path == dynamicField.realPath) {
          matched = item
          return true
        }

        if (item.children) {
          matched = this.filterNode(path, item.children)
          if (matched.path) {
            return true
          }
        }
      })
      return matched
    }
  }
}
</script>
<style lang="scss" scoped>
.nopadding {
  padding: 0;
}
</style>
